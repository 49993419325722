import { PhoneIcon, BuildingOffice2Icon, MapPinIcon, EnvelopeIcon } from '@heroicons/react/20/solid'
import { InformationCircleIcon } from '@heroicons/react/24/outline';


export default function DetailsForm(props) {
    return (
        <div id="contact-form" className="px-6 md:px-14 pt-10 md:pt-20 pb-20 z-10 relative isolate bg-white">
            <svg
                aria-hidden="true"
                className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            >
                <defs>
                    <pattern
                        x="50%"
                        y={-64}
                        id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                        width={200}
                        height={200}
                        patternUnits="userSpaceOnUse"
                    >
                        <path d="M100 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                </defs>
                <svg x="50%" y={-64} className="overflow-visible fill-gray-50">
                    <path
                        d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M299.5 800h201v201h-201Z"
                        strokeWidth={0}
                    />
                </svg>
                <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" width="100%" height="100%" strokeWidth={0} />
            </svg>
            <div className="mx-auto max-w-full">
                <div className="mt-4 flex flex-col lg:flex-row">
                    <div className="grid md:grid-cols-3 gap-x-14 gap-y-10 md:gap-y-0">
                        <div>
                            <h4 className="text-2xl font-bold tracking-tight text-gray-900">Information et Coordonnées</h4>
                            <div className="mt-6 md:mt-8">
                                <p className="text-md leading-6 md:leading-7 text-gray-900">
                                    Nos dentistes vous recoivent en semaine à Déols, du lundi au vendredi. En cas d’urgence médicale le week-end, consultez le dentiste ou médecin de garde de votre ville ou rendez-vous directement aux urgences de l’hôpital le plus proche.
                                </p>
                                {/* et médecin généraliste  */}
                                <figure>

                                    <dl className="flex flex-col gap-y-4 mt-4 md:mt-6 text-base leading-6 text-gray-800">
                                        <div className="flex gap-x-4">
                                            <dt className="flex-none">
                                                <span className="sr-only">Clinic</span>
                                                <BuildingOffice2Icon aria-hidden="true" className="h-6 w-5" />
                                            </dt>
                                            <dd>
                                                Centre de santé Oasis
                                            </dd>
                                        </div>
                                        <div className="flex gap-x-4">
                                            <dt className="flex-none">
                                                <span className="sr-only">Adresse</span>
                                                <MapPinIcon aria-hidden="true" className="h-6 w-5" />
                                            </dt>
                                            <dd>
                                                27 rue Ferdinand Gigot
                                                <br />
                                                36130 Déols
                                            </dd>
                                        </div>
                                        <div className="flex gap-x-4">
                                            <dt className="flex-none">
                                                <span className="sr-only">Téléphone</span>
                                                <PhoneIcon aria-hidden="true" className="h-6 w-5" />
                                            </dt>
                                            <a className='flex' href="tel:+33254351509">
                                                <a href="tel:+33254351509" className="hover:text-gray-900">
                                                    02 54 35 15 09
                                                </a>
                                            </a>
                                        </div>
                                    </dl>
                                </figure>
                            </div>
                        </div>
                        <div>
                            <h4 className="text-2xl font-bold tracking-tight text-gray-900">Nos disciplines médicales</h4>
                            <div className="mt-6 md:mt-8 px-2 md:px-6">
                                <ul style={{ listStyleType: 'disc' }} className='leading-6 md:leading-8 text-base text-gray-800'>
                                    <li>Dentisterie Générale</li>
                                    <li>Endodontie</li>
                                    <li>Parodontologie</li>
                                    <li>Implantologie</li>
                                    {/* <li>Médecine Générale</li> */}
                                </ul>
                            </div>
                        </div>
                        <div>
                            <h4 className="text-2xl font-bold tracking-tight text-gray-900">Nos horaires</h4>
                            <div className="my-6 md:my-8 px-2 md:px-6">
                                <ul style={{ listStyleType: 'disc' }} className='leading-6 md:leading-8 text-base text-gray-800'>
                                    <li>Lundi |  09:00 – 13:00  |  14:00 - 19h00</li>
                                    <li>Mardi |  09:00 – 13:00  |  14:00 - 19h00</li>
                                    <li>Mercredi |  09:00 – 13:00  |  14:00 - 19h00</li>
                                    <li>Jeudi |  09:00 – 13:00  |  14:00 - 19h00</li>
                                    <li>Vendredi |  09:00 – 13:00  |  14:00 - 19h00</li>
                                    {/* <li>Samedi |  09:00 – 12:30</li> */}
                                </ul>
                            </div>
                            <div className='flex items-center'>
                                <InformationCircleIcon className='w-20 h-20 mr-2' />
                                <p className='font-semibold'>La prise de rendez-vous se fait au téléphone uniquement, du lundi au vendredi de 11h à Midi et de 14h à 16h</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
