import { GoogleMap, MarkerF } from '@react-google-maps/api'
import { useRef, useCallback, useState, useEffect } from 'react';
import { mapStyle } from '../helpers/mapStyle'
import markerActive from '../assets/markerActive.svg'
import axios from 'axios';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

export default function ContactForm(props) {
    const mapRef = useRef()

    const [currentZoom] = useState(12)
    const [showMap, setShowMap] = useState(false)

    const [formSent, setFormSent] = useState(false)

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    function sendForm(e) {
        e.preventDefault()

        if (!name || !email || !phone || !message) {
            alert("Certains champs sont manquants")
            return
        }

        const requestBody = { name, phone, email, message }
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        axios.post('https://oasissante-server.onrender.com/api/comms/form', requestBody, { headers })
            .then(() => setFormSent(true))
            .catch(function (error) {
                alert("Erreur dans l'envoi du formulaire. Veuillez réessayer.")
            });
    }

    const locale = {
        key: 1,
        title: "DEOLS",
        specialty: 'Dentisterie & Médecine Générale',
        address: '27 rue Ferdinand Gigot, 36130 Déols',
        gmapsUrl: 'https://maps.app.goo.gl/Y7T5ifV69z7UwGXv5',
        rooms: "4",
        gps: { lat: 46.831237819177936, lng: 1.7006466074184041 }
    }

    const options = {
        styles: mapStyle,
        disableDefaultUI: true,
        zoomControl: true,
        gestureHandling: window.innerWidth < 500 ? "greedy" : "auto"
    }

    const mapProps = {
        center: {
            lat: 46.831237819177936,
            lng: 1.7006466074184041
        },
        zoom: 12
    };

    const mapContainerStyle = (window.innerWidth > 768 ?
        {
            height: '550px',
            width: '100%',
        }
        :
        {
            height: '38vh',
            width: `calc(${window.innerWidth}px - 3rem)`,
        }
    )

    const onMapLoad = useCallback(map => {
        mapRef.current = map
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setShowMap(true)
        }, 500);
    }, [])

    return (
        <div className="relative px-4 md:px-20 pt-16 pb-14 md:pt-32 md:pb-32" style={{ backgroundColor: '#231a2e' }}>
            {window.innerWidth > 768 && <div className='absolute top-1/2 -right-1/3 rounded-full -z-10 opacity-100' style={{ backgroundColor: '#231A2E', width: '300px', height: '300px' }} />}
            <div className="px-2 md:px-6 grid grid-cols-1 gap-y-10 md:gap-y-0 md:grid-cols-2 gap-x-10 items-center">
                <div className='googleMaps'>
                    {showMap &&
                        <GoogleMap
                            id="map"
                            zoom={mapProps.zoom}
                            center={mapProps.center}
                            styles={mapStyle}
                            mapContainerStyle={mapContainerStyle}
                            options={options}
                            onLoad={onMapLoad}
                        >
                            <MarkerF
                                key={`${locale.gps.lat}-${locale.gps.lng}`}
                                className="position-relative shadow-xl"
                                position={{
                                    lat: locale.gps.lat,
                                    lng: locale.gps.lng,
                                }}
                                icon={{
                                    url: markerActive,
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(28, 55),
                                    scaledSize: new window.google.maps.Size(70 * currentZoom / 12, 70 * currentZoom / 12),
                                }}
                            />
                        </GoogleMap>
                    }
                </div>
                <div className="text-white">
                    <h3 className='text-5xl font-bold mb-4'>Nous joindre</h3>
                    <p className='text-md leading-7'>
                        Notre équipe est toujours très heureuse de répondre à vos interrogations. Contactez-nous par téléphone au <a href="tel:+33254351509" className='font-bold'>02 54 35 15 09</a> ou, en dernier recours, via le formulaire ci-dessous.
                    </p>
                    <p className='text-md leading-7 mt-2'>En raison d'un grand nombre de demandes, nos délais de réponse peuvent être importants.</p>
                    <form onSubmit={(e) => sendForm(e)} className='mt-4'>
                        {!formSent ?
                            <>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 items-center py-2'>
                                    <input
                                        required
                                        id="name"
                                        name="name"
                                        type="text"
                                        placeholder='Nom *'
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-800 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                                    />
                                    {window.innerWidth > 768 && <p className='text-sm'>Les champs marqués d’un astérisque (*) sont obligatoires.</p>}
                                </div>
                                <div className='grid md:grid-cols-2 gap-y-4 md:gap-y-0 gap-x-4 items-center py-2'>
                                    <input
                                        required
                                        id="email"
                                        name="email"
                                        type="text"
                                        placeholder='Courriel *'
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-800 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                                    />
                                    <input
                                        required
                                        id="phone"
                                        name="phone"
                                        type="text"
                                        placeholder='Téléphone *'
                                        value={phone}
                                        onChange={e => setPhone(e.target.value)}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-800 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="py-2">
                                    <textarea
                                        id="message"
                                        name="message"
                                        placeholder='Votre message *'
                                        rows={window.innerWidth > 1024 ? 5 : 4}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-800 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                                        defaultValue={message}
                                        onChange={e => setMessage(e.target.value)}
                                    />
                                </div>
                                <div className='mt-2'>
                                    <button
                                        type="submit"
                                        className="text-center w-1/2 md:w-1/4 rounded-md cursor-pointer bg-gray-100 px-8 py-3 text-sm md:text-md font-semibold text-gray-900 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Envoyer <span aria-hidden="true">&rarr;</span>
                                    </button>
                                </div>
                            </>
                            :
                            <div className='flex flex-col items-center mt-8'>
                                <CheckCircleIcon aria-hidden="true" className="h-20 w-20" />
                                <p className='text-lg mt-2'>Formulaire envoyé</p>
                            </div>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}