import React, { useEffect } from 'react'
import './Equipe.css'

// Import pics
import susanaProfile from '../../assets/susanaProfile.jpg'
import raduProfile from '../../assets/raduProfile.jpg'
import angelineProfile from '../../assets/angelineProfile.jpg'
import marieProfile from '../../assets/marieProfile.jpg'
import zoeProfile from '../../assets/zoeProfile.jpg'
import auroreProfile from '../../assets/auroreProfile.jpg'
import alexandraProfile from '../../assets/alexandraProfile.jpg'
import teamCab from '../../assets/team1.jpg'

// Import components
import TeamFeature from '../../components/TeamFeature';
import DetailsForm from '../../components/DetailsForm';
import FeatureBlock from '../../components/FeatureBlock';


function Equipe(props) {

    useEffect(() => {
        if (!localStorage.getItem('equipe') && window.innerWidth > 768) {
            //Effects
            const bounceInUp = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('bounceInUp');
                    }
                });
            });
            bounceInUp.observe(document.querySelector('.featureBlock'));
            localStorage.setItem('equipe', true)
        }
    }, [])

    return (
        <div className='overflow-x-hidden'>
            {/* Home hero with separator */}
            <div className='heroContainerSmallEquipe'>
                <div className='heroTextContainerSmall'>
                    <h1 className='heroTitle md:mb-3 md:text-4xl md:mb-1 leading-tight text-3xl pb-3'>RENCONTREZ<br />NOTRE ÉQUIPE</h1>
                    <h3 className='heroSubtitle text-base md:text-xl pb-4'>
                        Nous collaborons étroitement pour que vous et votre famille aient accès à des soins de qualité
                    </h3>

                </div>
            </div>
            {window.innerWidth > 768 &&
                <div class="custom-shape-divider-bottom-1731245909">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" class="shape-fill"></path>
                    </svg>
                </div>
            }

            <div className='px-5 md:px-20 mt-16' >
                <FeatureBlock
                    title="La philosophie Oasis"
                    text="Notre modèle repose sur une collaboration étroite et un véritable esprit d'équipe, afin de garantir à nos patients des soins de la plus haute qualité. Nos dentistes, assistantes et secrétaires médicales s'engagent dans un processus de formation continue, enrichissant constamment leurs compétences. Grâce à ces échanges ainsi que les retours de nos patients, nous adaptons notre pratique pour vous offrir des soins modernes, sans douleurs et adaptés à vos besoins."
                    // , médecins généralistes
                    img={teamCab}
                    button="Voir l'équipe"
                    link="#equipe"
                />
            </div>

            <div id="equipe" className='px-4 md:px-0 pt-12 md:pt-24'>
                <TeamFeature
                    title={'Les praticiens'}
                    pitch={"Une équipe d'experts à votre service, dans toutes les disciplines de la médecine dentaire."}
                    people={[
                        {
                            name: 'Dr Susana Coelho',
                            role: 'Chirurgien-Dentiste | Omnipratique',
                            imageUrl: susanaProfile,
                            bio: "Le Dr Coelho cumule près de 20 ans d'expérience en tant que chirurgien-dentiste. Diplômée de l'université CESPU de Porto, elle a débuté sa carrière au Portugal avant de perfectionner son expertise pendant plus de 10 ans au Royaume-Uni, avec un intérêt particulier pour les traitements esthétiques. Cette expérience riche lui permet d’offrir à ses patients une prise en charge globale et de qualité.",
                        },
                        {
                            name: 'Dr Radu Zaharii',
                            role: 'Chirurgien-Dentiste | Omnipratique',
                            imageUrl: raduProfile,
                            bio: "Le Dr Zaharii est diplômé de l'université Victor Babes de Timisoara en Roumanie. Il a construit une expertise forte en endodontie et parodontologie mais maîtrise également tous traitements prothétiques et de dentisterie générale. Avec plus de 12 ans d'exercice de l'art dentaire, il a l'expérience des traitements complexes et propose des solutions innovantes à vos problèmes bucco-dentaires.",
                        }
                    ]}
                />
            </div>

            <div className='px-4 md:px-0 pt-24'>
                <TeamFeature
                    title={'Les assistantes dentaires'}
                    pitch={"Des assistantes à vos côtés à chaque étape, assurant confort, hygiène et assistance au fauteuil pour un parcours de soins efficient."}
                    people={[
                        {
                            name: 'Angeline',
                            role: 'Assistante Dentaire Qualifiée | Directrice Administrative',
                            imageUrl: angelineProfile,
                            bio: "Après des études en droit de la santé, Angeline décide de se réorienter vers le métier d’assistante dentaire. Forte de plus de 8 ans d’expérience dans des cabinets dentaires de la région, elle veille au respect des protocoles au sein de notre cabinet et assure la coordination des équipes d’assistantes dentaires et de secrétaires médicales.",
                        },
                        {
                            name: 'Marie',
                            role: 'Assistante Dentaire Qualifiée',
                            imageUrl: marieProfile,
                            bio: "Marie, forte de 4 ans d'expérience, a travaillé dans plusieurs cabinets en Auvergne et dans l'Indre. Elle a exploré diverses spécialités dentaires comme l'endodontie, l'implantologie et l'orthodontie, tout en se perfectionnant aux actes de dentisterie générale. Empathique et attentive, elle accompagne les patients en veillant à leur confort tout au long des soins.",
                        },
                        {
                            name: 'Zoé',
                            role: 'Assistante Dentaire en Formation',
                            imageUrl: zoeProfile,
                            bio: "Zoé a choisi de changer de voie professionnelle pour nous rejoindre en tant qu'assistante dentaire en formation. Dynamique et attentive, elle accompagne nos patients dans leur parcours de soins et participe à la préparation des plateaux techniques. Dotée d'un grand sens de l’écoute, elle sait informer et rassurer nos patients pour un traitement sans stress.",
                        },
                        {
                            name: 'Alexandra',
                            role: 'Assistante Dentaire en Formation',
                            imageUrl: alexandraProfile,
                            bio: "Alexandra a choisi le métier d'assistante dentaire après des études en BTS et plusieurs expériences dans le commerce et le conseil à la clientèle. Appliquée et à l'écoute, elle accueille et renseigne nos patients au secrétariat tout en se formant au métier d'assistante dentaire afin d'accompagner nos praticiens dans leurs soins. ",
                        }
                    ]}
                />
            </div>

            <div className='px-4 md:px-0 pt-24'>
                <TeamFeature
                    title={'Les secrétaires médicales'}
                    pitch={"Des professionnelles dévouées pour vous accueillir, vous orienter et vous assurer un parcours de soins fluide."}
                    people={[
                        {
                            name: 'Aurore',
                            role: 'Secrétaire Médicale',
                            imageUrl: auroreProfile,
                            bio: "Aurore, secrétaire médicale récemment diplômée, a travaillé pendant près d’un an au sein d’un Centre Hospitalier, en Centre Médico-Psychologique et en cabinet de gynécologie obstétrique. Forte de ses 10 années dans l'esthétique, elle a aussi développé un sens de l'organisation et une rigueur qui lui permettront d'assurer un suivi administratif efficace pour tous nos patients.",
                        },
                        // {
                        //     name: 'Alexandra',
                        //     role: 'Secrétaire Médicale',
                        //     imageUrl: unknown,
                        //     bio: 'XXXX',
                        // },
                    ]}
                />
            </div>

            {/* Contact and form */}
            <div className='px-2 md:px-8 mt-14 md:mt-40'>
                <DetailsForm />
            </div>
        </div>
    )
}

export default Equipe